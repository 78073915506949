import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

const EXTERNAL_RES_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  userId
  carbonDateTime
  description
  amount
  amountVat
  amountTotal
  supplierId
  documentXid
  originalFileName
  parentId
  category
  children {
    id
    createdAt
    updatedAt
    projectId
    userId
    carbonDateTime
    description
    amount
    amountVat
    amountTotal
    supplierId
    documentXid
    originalFileName
    parentId
    carbon {
      ${PROJECT_CARBON_FIELD}
    }
  }
  carbon {
    ${PROJECT_CARBON_FIELD}
  }
`;

const CUMPUTER_EQUIPMENT_FIELDS = `{
	id
	locationId
	type
	description
	quantity
}`;

const LOCATION_DATE = `{
	id
	locationId
	date
	location {
		id
	}
}`;

const LOCATION_FIELDS = `{
	id
	type
	type1
	kind
	status
	selected
	surface
	setName
	setName1
	setName2
	setName3
	scriptName
	description
	note
	foundOn
	mapPicture
	googleMapLink
	toilets
	trash
	fireExtinguisher
	catering
	addressId1
	addressId2
	addressId3
	addressId4
	addressId5
	addressId6
	addressId7
	addressId8
	addressId9
	addressId10
	addressId11
	addressId12
	addressId13
	addressId14

	ownerSupplierId
	dayRateFee
	dayRateSettingUpFee
	downPaiement
	total
	paiementType
	parkingPermit
	getInDate
	getOutDate
	shootingStartDate
	shootingEndDate

	elecSupplier
	elecMeterStart
	elecMeterEnd
	elecMeterStartPict
	elecMeterEndPict
	gasSupplier
	gasMeterStart
	gasMeterEnd
	gasMeterStartPict
	gasMeterEndPict
	waterSupplier
	waterMeterStart
	waterMeterEnd
	waterMeterStartPict
	waterMeterEndPict
	generatorFuelType
	generatorVolumeUsed
	generatorPict
	clientId
	numberOfPicture
	filmingArea
	mainCharacteristic
	totNbOfDaysOccupation
	totNbOfManDays
	energySavingPlan
	nbOfAirConditioners
	greenEnergySupplier
	recycledPaper
	sortedWaste
	documentAttachmentXid
	shootingNumDays
	paidByProduction

	address1 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address2 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address3 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address4 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address5 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address6 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address7 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address8 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address9 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address10 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address11 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address12 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address13 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	address14 {
		id
		name
		street
		number
		box
		zip
		state
		city
		country
		countryCode
		countryKey
		other
		lat
		lng
	}
	owner {
		id
		name
	}
	images
	imageName
	documents
	computerEquipments ${CUMPUTER_EQUIPMENT_FIELDS}
	documentAttachment {
		id
		xid
		xidThumb
		ext
	}
	locationDates ${LOCATION_DATE}
	date
	carbon {
		id
		description
		elecMeterEnd
		elecMeterStart
		paperPrinted
		type
		greenEnergySupplier
		sortedWaste
		recycledPaper
	}
}`;

/**
 * DECORATION
 */
const CARBON_DECORATION_FIELDS = `
	costPurchaseSetsTotal
	costMakingSetsTotal
	costRentingSetsTotal
	costCreationSetsTotal
	ecoConceptionSetTotal
	sortingWaste
	materialSorted
	paintLiterWasteTotal
	cardboardWasteTotal
	woodWasteTotal
	steelWasteTotal
	plasticWasteTotal
`;

/**
 * Costumes, Hairs and Makeups
 */
const CARBON_COSTUMES_HAIRS_MAKEUPS_FIELDS = `
	costumeBuyTotal
	costumeMakingTotal
	costumeRentalTotal
	costumeReuse
	makeupTotal
	hairCostTotal
`;

/**
 * Transportation
 */
const CARBON_TRANSPORTATION_FIELDS = `
	sumDieselKm
	sumPetrolKm
	sumHybridKm
	sumElectricKm
	sumTaxiKm
	sumPlaneKm
	sumTrain
	sumMotorcycleLarge
	sumScooterGasoline
	sumScooterElectric
	sumPrivateJet
	sumIntercityTrain
	sumBus
	sumFerryBoat
	sumFreightTrain
	sumFreightPlane
	sumContainerShip
	sumFreightSmallTruckKm
	sumFreightSmallTruckKg
	sumFreightLargeTruckKm
	sumFreightLargeTruckKg
	sumFreightTrainKg
	sumFreightPlaneKg
	sumContainerShipKg
`;

/**
 * Management
 */
const CARBON_MANAGEMENT_FIELDS = `
	setSmallConsumableTotal
	setMaterialSorted
	setCardboardWasteTotal
	setPaintLiterWasteTotal
	setWoodWasteTotal
	setSteelWasteTotal
	setPlasticWasteTotal
	setMixedWasteTotal
	nbOvernight
	nbRedMeatCatering
	nbWhiteMeatCatering
	nbVegetarianCatering
	nbNotSpecifiedCatering
`;

/**
 * TECHNICAL
 */
const CARBON_TECHNICAL_FIELDS = `
	nbrBatteriesThrown
	amountCameraRental
	amountLightingRental
	amountMachineryRental
	amountSoundMaterialRental
	amountOfFilmsSupport
	amountOfSpecialEffect
	sumCarsLogesKm
	sizeOfCarLoge
	energyTypeOfCarLoge
	volumeEnergyCarLoge
	carDiesel
	carElectric
	carGasoline
	ulmPlaneClassTwo
	motoLarge
	helicopter
	ulmPlaneClassThree
`;

/**
 * Post PRODUCTION
 */
const CARBON_POST_PRODUCTION_FIELDS = `
	numDaysVideoEditing
	videoEditingCountry
	numDaysSoundEditing
	soundEditingCountry
	numDaysVfx
	vfxCountry
	numDaysColorGrading
	colorGradingCountry
	numDaysMasteringAndLab
	masteringAndLabCountry
	dataArchivedLTO
	numCopiesArchivedLTO
	dataArchivedHDD
	numCopiesArchivedHDD
	dataArchivedOnCloud
	numCopiesArchivedOnCloud
`;

const CARBON_OTHERS_FIELDS = `
	majorityProducingCountry
	countryOfCoProduction
	premises ${LOCATION_FIELDS}
	locations ${LOCATION_FIELDS}
	hardwares ${CUMPUTER_EQUIPMENT_FIELDS}
`;

const RES_CARBON_CLAP = `
	id
	createdAt
	updatedAt
	projectId
	finalReport
	uuid
	ccProjectName
	apiKey
	login
	password
	userId
	currency
	productionType
	budget
	totalProduction
	numberOfEpisode
	filmLength
	shootingFormat
	startDate
	endDate
	production
	country
	coProdCountries
	${CARBON_DECORATION_FIELDS}
	${CARBON_COSTUMES_HAIRS_MAKEUPS_FIELDS}
	${CARBON_TRANSPORTATION_FIELDS}
	${CARBON_MANAGEMENT_FIELDS}
	${CARBON_TECHNICAL_FIELDS}
	${CARBON_POST_PRODUCTION_FIELDS}
	${CARBON_OTHERS_FIELDS}
`;

const RES_CARBON_TEMPLATE = `
  id
  licenseId
  numberOfUse
  efficiency
  name
  coTwoCategory
  type
  subType
  km
  liter
  mcube
  kwh
  kg
  msquare
  costCenters
  number
  boolValue
  mealRed
  mealWhite
  mealVege
  mealNotSpecified
  position
  supplierId
  searchInDescOnly
  keyWordOne
  keyWordTwo
  keyWordThree
  userId
  unitAmountTotal
  vehiculeId
  reason
  additionalSubType
  waterVolumeUsed
  disable
  locationId
  supplier {
    id
    name
  }
  carbonValues {
    id
    carbonId
    carbonModel
    kgCoTwo
  }
  user {
    name
    firstName
  }
`;

export const GetKmGreenImpact = async (km, category) => {
	try {
		const mutation = gql`
			query ($Km: Float!, $Category: Int!) {
				GetKmGreenImpact(Km: $Km, Category: $Category)
			}
		`;
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				Category: parseInt(category),
				Km: parseFloat(km)
			}
		});

		return data.GetKmGreenImpact;
	} catch (e) {
		console.log({ e });
	}
};

export const moveAndReorganizeCarbonTemplatePosition = async (CarbonTemplateId, Position) => {
	try {
		const mutation = gql`
			mutation ($CarbonTemplateId: ID!, $Position: ID!) {
				MoveAndReorganizeCarbonTemplatePosition(CarbonTemplateId: $CarbonTemplateId, Position: $Position)
			}
		`;

		const {
			data: { MoveAndReorganizeCarbonTemplatePosition }
		} = await apollo.mutate({
			mutation,
			variables: {
				CarbonTemplateId: +CarbonTemplateId,
				Position: +Position
			}
		});

		return MoveAndReorganizeCarbonTemplatePosition;
	} catch (e) {
		console.log(e);
	}
};

export const generateForecastCarbonEntriesBasedOnBudget = async () => {
	try {
		const mutation = gql`
			mutation {
				GenerateForecastCarbonEntriesBasedOnBudget
			}
		`;

		const {
			data: { GenerateForecastCarbonEntriesBasedOnBudget }
		} = await apollo.mutate({
			mutation
		});

		return GenerateForecastCarbonEntriesBasedOnBudget;
	} catch (e) {
		console.log(e);
	}
};

export const getProjectCarbon = async (filter = {}) => {
	try {
		const query = gql`
      query ($filter: AdvancedGlobalFilterInput) {
        GetProjectCarbon(Filter: $filter) {
          ${PROJECT_CARBON_FIELD}
        }
      }
    `;

		const {
			data: { GetProjectCarbon }
		} = await apollo.query({
			query,
			variables: {
				filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const updCarbon = async (carbonId, inputCarbon) => {
	try {
		const mutation = gql`
			mutation ($carbonId: ID!, $inputCarbon: CarbonInput!) {
				UpdCarbon(CarbonId: $carbonId, UpdCarbon: $inputCarbon) {
          ${PROJECT_CARBON_FIELD}
        }
			}
		`;

		const {
			data: { UpdCarbon }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonId: parseInt(carbonId, 10),
				inputCarbon
			}
		});

		return UpdCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const newCarbon = async (inputCarbon) => {
	try {
		const mutation = gql`
      mutation ($inputCarbon: CarbonInput!) {
        NewCarbon(NewCarbon: $inputCarbon) {
          ${PROJECT_CARBON_FIELD}
        }
      }
    `;

		const {
			data: { NewCarbon }
		} = await apollo.mutate({
			mutation,
			variables: {
				inputCarbon
			}
		});

		return NewCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const getCarbon = async (parentId, entityType) => {
	try {
		const query = gql`
      query($parentId: Int!, $entityType: Int!) {
        GetCarbon(ParentId: $parentId, EntityType: $entityType) {
          ${PROJECT_CARBON_FIELD}
        }
      }
    `;

		const {
			data: { GetCarbon }
		} = await apollo.query({
			query,
			variables: {
				parentId: +parentId,
				entityType: +entityType
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const addUpdCarbonFromExternalSource = async (externalId = null, externalInput = {}, carbonInput = {}) => {
	try {
		const mutation = gql`
      mutation ($externalId: ID, $externalInput: CarbonFromExternalSourceInput!) {
        AddUpdCarbonFromExternalSource(
          IdCarbonFromExternalSourceToUpdate: $externalId
          CarbonFromExternalSourceInput: $externalInput
        ) {
          ${EXTERNAL_RES_FIELD}
        }
      }
    `;

		const {
			data: { AddUpdCarbonFromExternalSource }
		} = await apollo.mutate({
			mutation,
			variables: {
				externalId,
				externalInput
			}
		});

		return AddUpdCarbonFromExternalSource;
	} catch (e) {
		console.log(e);
	}
};

export const getCarbonFromExternalSource = async (externalId = 0) => {
	try {
		const query = gql`
      query ($externalId: ID!){
        GetCarbonFromExternalSource(CarbonFromExternalSourceId: $externalId) {
          ${EXTERNAL_RES_FIELD}
        }
      }
    `;

		const {
			data: { GetCarbonFromExternalSource }
		} = await apollo.query({
			query,
			variables: {
				externalId: parseInt(externalId)
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbonFromExternalSource;
	} catch (e) {
		console.log(e);
	}
};

export const delCarbonFromExternalSource = async (carbonExternalSourceId) => {
	try {
		const mutation = gql`
			mutation ($carbonExternalSourceId: [ID!]!) {
				DelCarbonFromExternalSource(CarbonExternalSourceId: $carbonExternalSourceId)
			}
		`;

		const {
			data: { DelCarbonFromExternalSource }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonExternalSourceId
			}
		});

		return DelCarbonFromExternalSource;
	} catch (e) {
		console.log(e);
	}
};

export const getProjectCarbonClap = async (reporting = false) => {
	try {
		const query = gql`
      query ($reporting: Boolean!) {
        GetProjectCarbonClap(Reporting: $reporting) {
          ${RES_CARBON_CLAP}
        }
      }
    `;

		const {
			data: { GetProjectCarbonClap }
		} = await apollo.query({
			query,
			variables: {
				reporting
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectCarbonClap;
	} catch (e) {
		console.log(e);
	}
};

export const reportCarbonClap = async (reporting = false, isApi = false, updCarbonClap = {}) => {
	const mutation = gql`
		mutation ($reporting: Boolean!, $isApi: Boolean!, $updCarbonClap: CarbonClapInput) {
			UpdCarbonClap(Reporting: $reporting, ReportCarbonClap: $isApi, UpdCarbonClap: $updCarbonClap)
		}
	`;

	const {
		data: { UpdCarbonClap }
	} = await apollo.mutate({
		mutation,
		variables: {
			reporting,
			isApi,
			updCarbonClap
		}
	});

	return UpdCarbonClap;
};

export const getTotalNumberProjectCarbon = async (filter = {}) => {
	try {
		const query = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetTotalNumberProjectCarbon(Filter: $filter)
			}
		`;

		const {
			data: { GetTotalNumberProjectCarbon }
		} = await apollo.query({
			query,
			variables: {
				filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetTotalNumberProjectCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const addUpdCarbonTemplate = async (carbonTemplateId = 0, carbonTemplateInput = {}) => {
	try {
		const mutation = gql`
			mutation ($carbonTemplateId: ID, $carbonTemplateInput: CarbonTemplateInput!) {
				AddUpdCarbonTemplate(CarbonTemplateId: $carbonTemplateId, CarbonTemplateInput: $carbonTemplateInput)
			}
		`;

		const {
			data: { AddUpdCarbonTemplate }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonTemplateId: +carbonTemplateId,
				carbonTemplateInput
			}
		});

		return AddUpdCarbonTemplate;
	} catch (e) {
		console.log({ ERROR_ADD_UPD_CARBON_TEMPLATE: e });
	}
};

export const getCarbonTemplate = async (carbonTemplateId) => {
	try {
		const query = gql`
      query ($carbonTemplateId: ID!) {
        GetCarbonTemplate(CarbonTemplateId: $carbonTemplateId) {
          ${RES_CARBON_TEMPLATE}
        }
      }
    `;

		const {
			data: { GetCarbonTemplate }
		} = await apollo.query({
			query,
			variables: {
				carbonTemplateId: +carbonTemplateId
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbonTemplate;
	} catch (e) {
		console.log({ ERROR_GET_CARBON_TEMPLATE: e });
	}
};

export const getCarbonTemplates = async (filter, hideEmptyName = false) => {
	try {
		const query = gql`
      query($filter: AdvancedGlobalFilterInput, $hideEmptyName: Boolean) {
        GetCarbonsTemplates(Filter: $filter, HideEmptyName: $hideEmptyName) {
          ${RES_CARBON_TEMPLATE}
        }
      }
    `;

		const {
			data: { GetCarbonsTemplates }
		} = await apollo.query({
			query,
			variables: {
				filter,
				hideEmptyName
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbonsTemplates;
	} catch (e) {
		console.log({ ERROR_GET_CARBON_TEMPLATES: e });
	}
};

export const deleteCarbonTemplate = async (carbonTemplateId) => {
	const mutation = gql`
		mutation ($carbonTemplateId: ID!) {
			DelCarbonTemplate(CarbonTemplateId: $carbonTemplateId)
		}
	`;

	const {
		data: { DelCarbonTemplate }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonTemplateId: +carbonTemplateId
		}
	});

	return DelCarbonTemplate;
};

export const runAutomation = async (carbonTemplateId = null, runAll, carbonId = null) => {
	try {
		const mutation = gql`
			mutation ($carbonTemplateId: ID, $runAll: Boolean!, $carbonId: [ID]) {
				RunAutomationOfCarbonTemplate(CarbonTemplateId: $carbonTemplateId, RunAll: $runAll, CarbonId: $carbonId) {
          id
          nbrProcessed
          nbrUpdate
          nbrNotUpdate
          log
          carbons {
            ${PROJECT_CARBON_FIELD}
          }
        }
			}
		`;

		const {
			data: { RunAutomationOfCarbonTemplate }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonTemplateId: +carbonTemplateId,
				runAll,
				carbonId
			}
		});

		return RunAutomationOfCarbonTemplate;
	} catch (e) {
		console.log({ ERROR_RUN_AUTOMATION: e });
	}
};

export const importCarbonClapExternalSource = async (docXid, importDetails = false, runAutomation = false) => {
	try {
		const mutation = gql`
			mutation ($docXid: [String!]!, $importDetails: Boolean!, $runAutomation: Boolean!) {
				ImportCarbonClapExternalSource(DocXid: $docXid, ImportDetails: $importDetails, RunAutomation: $runAutomation) {
          ${PROJECT_CARBON_FIELD}
        }
			}
		`;

		const {
			data: { ImportCarbonClapExternalSource }
		} = await apollo.mutate({
			mutation,
			variables: {
				docXid,
				importDetails,
				runAutomation
			}
		});

		return ImportCarbonClapExternalSource;
	} catch (e) {
		console.log({ ERROR_IMPORT_CARBON_CLAP_EXTERNAL_SOURCE: e });
	}
};

export const checkIfCarbonTemplateExist = async () => {
	try {
		const query = gql`
			query {
				CheckIfCarbonTemplateExist
			}
		`;

		const {
			data: { CheckIfCarbonTemplateExist }
		} = await apollo.query({
			query,
			fetchPolicy: 'no-cache'
		});

		return CheckIfCarbonTemplateExist;
	} catch (e) {
		console.log({ ERROR_CHECK_IF_CARBON_TEMPLATE_EXIST: e });
	}
};

export const changeCarbonVerifiedStatus = async (carbonIds = [], status = 3) => {
	try {
		const mutation = gql`
			mutation ($carbonIds: [ID!]!, $status: Int!) {
				ChangeCarbonVerifiedStatus(ArrayCarbonId: $carbonIds, Verified: $status)
			}
		`;

		const {
			data: { ChangeCarbonVerifiedStatus }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonIds,
				status
			}
		});

		return ChangeCarbonVerifiedStatus;
	} catch (e) {
		console.log({ CHANGE_CARBON_VERIFIED_STATUS: e });
	}
};

export const getCarbonFiles = async (carbonId) => {
	try {
		const query = gql`
			query ($carbonId: ID!) {
				GetPictureXidOfCarbon(CarbonId: $carbonId)
			}
		`;

		const {
			data: { GetPictureXidOfCarbon }
		} = await apollo.query({
			query,
			variables: {
				carbonId: +carbonId
			},
			fetchPolicy: 'no-cache'
		});

		return GetPictureXidOfCarbon;
	} catch (error) {
		console.error({ error });
	}
};

export const addUpdSliceCarbon = async (sliceCarbon = [], entityType = 0, entityId = 0) => {
	const mutation = gql`
    mutation ($sliceCarbon: [CarbonInput!]!, $entityType: Int!, $entityId: Int!) {
      AddUpdSliceCarbon(NewSliceCarbon: $sliceCarbon, EntityType: $entityType, EntityId: $entityId) {
        ${PROJECT_CARBON_FIELD}
      }
    }
  `;

	const {
		data: { AddUpdSliceCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			sliceCarbon,
			entityType: +entityType,
			entityId: +entityId
		}
	});

	return AddUpdSliceCarbon;
};

export const delCarbon = async (carbonIds = []) => {
	const mutation = gql`
		mutation ($carbonIds: [ID!]!) {
			DelCarbon(CarbonId: $carbonIds)
		}
	`;

	const {
		data: { DelCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonIds
		}
	});

	return carbonIds;
};
